import React from 'react';

import SectionComponent from '../components/sections/sections';

const NotFoundPage = (): JSX.Element => {
  return (
    <SectionComponent filter='404' />
  );
};

export default NotFoundPage;
